module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","lang":"en","name":"InTown Mykonos","short_name":"InTown Mykonos is a private property located in Mykonos, Greece","description":"InTown Mykonos offers the perfect setting for indulging holidays, romantic escapes and family holidays in the captivating island of Cyclades in Greece, Mykonos.","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"./src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2c2a1f95e958fce80afe170aa0b272b4"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
